import classnames from "classnames";
import React from "react";

import styles from "./styles.scss";

type TextVariant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "body1"
  | "body2"
  | "subtitle1"
  | "subtitle2"
  | "caption"
  | "overline";

type FontWeight =
  | "thin"
  | "ultraLight"
  | "light"
  | "regular"
  | "medium"
  | "semiBold"
  | "bold"
  | "extraBold"
  | "black";

type TextOverflow = "clip" | "ellipsis" | undefined;

export type Props = {
  children?: React.ReactNode;
  color?: IonicThemeShade;
  variant?: TextVariant;
  weight?: FontWeight;
  textOverflow?: TextOverflow;
  className?: string;
};

type State = {};

export default class UIText extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const {
      color,
      variant = "body1",
      weight = "regular",
      textOverflow,
      className = "",
    } = this.props;

    // NOTE: For color + shade, look at common/css/default.scss for the ionic color config.
    return (
      <span
        className={classnames(styles.root, className, {
          [styles.colorPrimary]: color === "primary",
          [styles.colorSecondary]: color === "secondary",
          [styles.colorTertiary]: color === "tertiary",
          [styles.colorDark]: color === "dark",
          [styles.colorMedium]: color === "medium",
          [styles.colorLight]: color === "light",
          [styles.colorWhite]: color === "white",
          [styles.colorSuccess]: color === "success",
          [styles.colorWarning]: color === "warning",
          [styles.colorDanger]: color === "danger",
          [styles.body1]: variant === "body1",
          [styles.body2]: variant === "body2",
          [styles.h1]: variant === "h1",
          [styles.h2]: variant === "h2",
          [styles.h3]: variant === "h3",
          [styles.h4]: variant === "h4",
          [styles.h5]: variant === "h5",
          [styles.h6]: variant === "h6",
          [styles.overline]: variant === "overline",
          [styles.subtitle1]: variant === "subtitle1",
          [styles.weightThin]: weight === "thin",
          [styles.weightUltraLight]: weight === "ultraLight",
          [styles.weightLight]: weight === "light",
          [styles.weightMedium]: weight === "medium",
          [styles.weightSemiBold]: weight === "semiBold",
          [styles.weightBold]: weight === "bold",
          [styles.weightExtraBold]: weight === "extraBold",
          [styles.weightBlack]: weight === "black",
          [styles.textOverflow]: !!textOverflow,
          [styles.textOverflowEllipsis]: textOverflow === "ellipsis",
        })}
      >
        {this.props.children}
      </span>
    );
  };
}
