import React, { Suspense } from "react";
import { Redirect, Route, Switch, withRouter, RouteComponentProps } from "react-router";

import { UNKNOWN_ROUTE, WELCOME_ROUTE, JOIN_ROUTE, JOIN_BY_REFERRAL_ROUTE } from "constants/routes";

/* Main views */
import FourOhFour from "pages/FourOhFour";

const WelcomePage = React.lazy(() => import("pages/Welcome"));
const JoinByReferralPage = React.lazy(() => import("pages/JoinByReferral"));

type Props = RouteComponentProps & {};

type State = {};

const DEFAULT_ROUTE = WELCOME_ROUTE;

class AppRouter extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <Switch>
        <Redirect exact from="/" to={`/${DEFAULT_ROUTE}`} />

        <Route path={`/${WELCOME_ROUTE}`}>
          <Suspense fallback={null}>
            <WelcomePage />
          </Suspense>
        </Route>

        <Route path={`/${JOIN_ROUTE}/${JOIN_BY_REFERRAL_ROUTE}`}>
          <Suspense fallback={null}>
            <JoinByReferralPage />
          </Suspense>
        </Route>

        <Route path={`/${UNKNOWN_ROUTE}`}>
          <FourOhFour />
        </Route>

        <Redirect to={`/${UNKNOWN_ROUTE}`} />
      </Switch>
    );
  };
}

export default withRouter(AppRouter);
