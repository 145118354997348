/* eslint-disable import/first */
import { setupConfig } from "@ionic/react";
import React from "react";
import ReactDOM from "react-dom";

import "common/css/_init";
import "./polyfills";
import "./index.scss";
import "common/utils/i18n";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

import BootLoader from "boot-loader";

import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// Force all UI to feel like iOS for consistency
// Prevent swiping back from doing some weird navigation thing on IOS
// https://github.com/ionic-team/ionic-framework/issues/16441
// https://github.com/ionic-team/ionic-framework/issues/20904
setupConfig({
  mode: "ios",
});

ReactDOM.render(
  <React.StrictMode>
    <BootLoader />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
