export const WELCOME_ROUTE = "welcome";
export const JOIN_ROUTE = "join";
export const JOIN_BY_REFERRAL_ROUTE = "referral";
export const UNKNOWN_ROUTE = "404";

const REFERRER_QUERY_PARAM = "referrer";
const APP_LINK_QUERY_PARAM = "appLink";

type QueryParamDefinition = {
  key: string;
  type?: number | string | boolean;
};

type QueryParamConfig = { [key: string]: QueryParamDefinition | QueryParamConfig };

// This QUERY_PARAM object helps us manage what query params are used across the app.
// We also want to know what the type of that query param is, so this makes it very easy
// to remind us as the app gets larger.
// ===== HOW TO ADD A NEW QUERY PARAM =====
// 1. Add a query param string above.
// 2. Add a query param config definition for the route you want to support to the type below.
// 3. Add the query param to the actual exported config object.
// 4. Access the query param like QUERY_PARAMS.root.showCreateClub.key.
type SupportedQueryParamsByRoute = { [key: string]: QueryParamConfig } & {
  root: {};
  join: {
    referrer: {
      key: "referrer";
      type?: string;
    };
    appLink: {
      key: "appLink";
      type?: string;
    };
  };
};

export const QUERY_PARAMS: SupportedQueryParamsByRoute = {
  root: {},
  join: {
    referrer: {
      key: REFERRER_QUERY_PARAM,
    },
    appLink: {
      key: APP_LINK_QUERY_PARAM,
    },
  },
};
