import React from "react";

type Props = {};

type State = {};

export default class AppRunAfterInit extends React.Component<Props, State> {
  componentDidMount = (): void => {
    // eslint-disable-next-line no-console
    console.log("App has initialized!");
  };

  render = (): React.ReactNode => {
    return null;
  };
}
