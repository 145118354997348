import React from "react";

export type ExposedProps = {
  someValue: string;
};

type Props = {
  children: React.ReactNode;
};

type State = {};

const INITIAL_CONTEXT: ExposedProps = {
  someValue: "initial",
};

const AppStateContext = React.createContext(INITIAL_CONTEXT);

export class AppStateProvider extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <AppStateContext.Provider
        value={{
          someValue: "initial",
        }}
      >
        {children}
      </AppStateContext.Provider>
    );
  };
}

export const AppStateConsumer = AppStateContext.Consumer;
