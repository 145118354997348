import React from "react";

import { AppStateProvider } from "providers/AppStateProvider";

import AppRouter from "boot-loader/components/AppRouter";
import AppRunAfterInit from "boot-loader/components/AppRunAfterInit";

type Props = {};

type State = {};

export default class App extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <AppStateProvider>
        <AppRunAfterInit />
        <AppRouter />
      </AppStateProvider>
    );
  };
}
