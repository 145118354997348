import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { ReactComponent as NianticLogoVertical } from "assets/images/niantic/niantic-logo-vertical.svg";

import DialogBoxPageLayout from "common/components/DialogBoxPageLayout";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {};

type State = {};

class FourOhFourPage extends React.PureComponent<Props, State> {
  render = (): React.ReactNode => {
    return (
      <DialogBoxPageLayout className={styles.root}>
        <div className={styles.header}>
          <UIText variant="h2" color="dark" weight="bold">
            {this.props.t("SORRY_WE_COULD_NOT_FIND_WHAT_YOU_LOOKING_FOR")}
          </UIText>
        </div>

        <UISpacer h={16} />

        <div className={styles.logoCtn}>
          <NianticLogoVertical className={styles.logo} />
        </div>
      </DialogBoxPageLayout>
    );
  };
}

export default withTranslation()(FourOhFourPage);
