import React from "react";
import { Link } from "react-router-dom";

export type Props = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string;
  openInNewTab?: boolean;
};

type State = {};

class UILink extends React.Component<Props, State> {
  renderAsLocalLink = (): React.ReactNode => {
    const {
      // We wanna spread it all, but openInNewTab is not a supported attribute of an anchor el.
      href,
      children,
      openInNewTab,
      ...remainingProps
    } = this.props;

    return (
      <Link {...remainingProps} target={openInNewTab ? "_blank" : undefined} to={href}>
        {children}
      </Link>
    );
  };

  renderAsExternalLink = (): React.ReactNode => {
    const {
      // We wanna spread it all, but openInNewTab is not a supported attribute of an anchor el.
      href,
      children,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      openInNewTab,
      ...remainingProps
    } = this.props;

    return (
      <a {...remainingProps} href={href} rel="noopener noreferrer" target="_blank">
        {children}
      </a>
    );
  };

  render = (): React.ReactNode => {
    const isLocalHref = (this.props.href || "")[0] === "/";

    // If the href is a local path AND we have no explicitly said to open in a new tab,
    // treat it like a local route.
    // If the href is defined more as a full url, open it in a new tab.
    // If there is no href, then just render it as if it were underlined text.
    if (isLocalHref) {
      return this.renderAsLocalLink();
    }

    return this.renderAsExternalLink();
  };
}

export default UILink;
