import React, { Suspense } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { BrowserRouter } from "react-router-dom";

import UIErrorBoundary from "common/components/UIErrorBoundary";

import App from "boot-loader/components/App";
import ServiceProviders from "boot-loader/components/ServiceProviders";
import UIProviders from "boot-loader/components/UIProviders";

type Props = RouteComponentProps & {};

type State = {};

class BootLoader extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <UIErrorBoundary>
        <Suspense fallback={null}>
          <App />
        </Suspense>
      </UIErrorBoundary>
    );
  };
}

const BootLoaderRouterConnected = withRouter(BootLoader);
const BootLoaderWrapper = (): React.ReactElement => (
  <BrowserRouter>
    <UIProviders>
      <ServiceProviders>
        <BootLoaderRouterConnected />
      </ServiceProviders>
    </UIProviders>
  </BrowserRouter>
);

export default BootLoaderWrapper;
