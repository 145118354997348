import React from "react";

type Props = {
  children: React.ReactNode;
};

type State = {};

export default class UIProviders extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const { children } = this.props;

    // Any accessible providers go here! Things like showing toasts, as thats UI related.
    // Each provider wrapping children here should not care about authentication state.
    return children;
  };
}
