import classnames from "classnames";
import React from "react";

import PrivacyFooter from "common/components/PrivacyFooter";

import styles from "./styles.scss";

type Props = {
  children: React.ReactNode;
  className?: string;
};

type State = {};

class DialogBoxPageLayout extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const { className = "" } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.bgImage}>
          <div className={styles.content}>
            <div className={classnames(styles.dialogBox, className)}>{this.props.children}</div>
          </div>
          <div className={styles.footer}>
            <PrivacyFooter />
          </div>
        </div>
      </div>
    );
  };
}

export default DialogBoxPageLayout;
