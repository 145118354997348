import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { NIANTIC_PRIVACY_URL, NIANTIC_TERMS_URL } from "constants/urls";

import UILink from "common/components/UILink";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {};

type State = {};

const PUBLICATION_YEAR = 2021;

// Generates the copyright that follows this format "[year of first publication-current year] Niantic, Inc. All Rights Reserved."
export const getCopyrightYear = (publicationYear: number): string => {
  const currentYear = new Date().getFullYear();
  const yearString =
    currentYear > publicationYear
      ? `${publicationYear}-${currentYear}`
      : publicationYear.toString();

  return yearString;
};

class PrivacyFooter extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        <div className={styles.links}>
          <UILink href={NIANTIC_TERMS_URL} openInNewTab>
            <UIText color="white" variant="body2" weight="bold">
              {this.props.t("TERMS")}
            </UIText>
          </UILink>
          <UISpacer w={8} />
          <span className={styles.dot}>&#183;</span>
          <UISpacer w={8} />
          <UILink href={NIANTIC_PRIVACY_URL} openInNewTab>
            <UIText color="white" variant="body2" weight="bold">
              {this.props.t("PRIVACY")}
            </UIText>
          </UILink>
        </div>
        <div className={styles.copyright}>
          <UIText color="white" variant="body2">
            {this.props.t("NIANTIC_INC_ALL_RIGHTS_RESERVED", {
              year: getCopyrightYear(PUBLICATION_YEAR),
            })}
          </UIText>
        </div>
      </div>
    );
  };
}

export default withTranslation()(PrivacyFooter);
