import classnames from "classnames";
import React, { ErrorInfo } from "react";
import { withRouter, RouteComponentProps } from "react-router";

import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = RouteComponentProps & {
  children: React.ReactNode;
  className?: string;

  // Similar to onRetry, but gives you full control of the error UI. You can attach the
  // clearError method to whatever in your UI you want.
  renderError?: (clearError: () => void) => React.ReactNode;
};

type State = {
  hasError: boolean;
};

class UIErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidCatch = (error: Error, info: ErrorInfo) => {
    this.setState({ hasError: true });

    // eslint-disable-next-line no-console
    console.error(error, info);
  };

  clearError = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      // If we want a custom error UI
      if (this.props.renderError) {
        return this.props.renderError(this.clearError);
      }

      // Default error UI
      return (
        <div className={classnames(styles.root, this.props.className)}>
          <UISpacer h={24} />

          <UIText variant="body1" color="dark" weight="medium">
            Sorry something went wrong.
          </UIText>
        </div>
      );
    }

    // Otherwise, no errors, show the main view.
    return this.props.children;
  }
}

export default withRouter(UIErrorBoundary);
